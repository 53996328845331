<template>
    <pdfView pdf="plain_wealth_doc.pdf" style="height: 100vh;"></pdfView>
</template>

<script>
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";

export default {
  components: {
    pdfView: VuePdfApp,
  },
};
</script>